import "./libhalo";
import URL from "url-parse";

/*
  Data
*/

const keys = {
  teal: [
    "043e631ab57477c04d061bef0c507cd7c440b9508eae994a7ab047fd87e05721e5344243fe4c67cd6c2a8d9331b2cdb26d82c0592888500b45bc26d7c39378df9f",
    "04973086bd04ee8769edc537951b4ccf1b1a336197d68e99c6810d1f8a826cbe5f31396781372be09a4296d0ec98b370847eb77c82851fa93ec134ede8ba286a48",
    "0483a6f1b46454c8332ed851f587fc14cb47d978551345d7895bee1ab9c9afce34298d9a29e20e636e14d81e1db37008b7090919daf21220262574a3d966cb25ad",
    "0494eec3cb0a4658135566fdeeb6fc19fa57a9ed3864db851f21a4a9ec1c3197a3bc3ab1d0ee732088b5341b5df2f7ab1fcb6ec74bc4a6cf9f2be101dc48ff84a6",
    "046e3b3a8e1abd7848ab3f2beca18cee5e907fb2ca89d59af49e5dc608c912c3932ac7c1e48c1a295a15555ae89e44c2ca05c4b3a3c2c855792d83e3a295b7b91e",
    "045c828b93dba6676674cb17205d371f3cf46c019bc2bbee6944b07f22ee915d0f5ae5b1ecfc20eba9bbabc6cf7588e0a6dbed49fd650843af18579dea4d775659",
    "04de2245ebe1cf0419a41a89a35f4b720d93bef28ae6dccf9f3c282455824a01557799fc134d27a0555dbf836e1fca6862bae72249be1a0deef78430bf01889e4d",
    "04e8f1ed5dd213938cffb792a5c3f8562d3c60bd762d2cad0a4be8e430c0c9ed8a5739f9246ba19d2f97b1244dcb8269c125a3d4823309b39beea964f7d9a3af57",
    "0431f2956dd2b400c5ce4e80f5f0e99d6608425b09f0869d712e0ef0550d7f5e3a7dcc0bcf571176b1be578100aa0df21a770ad4bbf97a58efcbf621052b84022b",
    "048f8f19656aee055939537acc9f276da7c43da04c850f8b931d7bb14d75aa9b12fd30c75f172f3f30229cea7c024228e19f44352e6c410efbd5cd0bc178090f4c",
  ],
  purple: [
    "0434f8cfcb50bcb77010e6cea7538ed06a95b128efeab6ff8f01c4c14bdc3ffd13d4d66ec5bc090d96265b4c2fe3429de789e0afd32a21a2787517efead350a8be",
    "046627e50f49e98c2d4e90a924bc0d7ecb6b3d344e7bb032d1fcd13188b2bc7e60fd74e239bd50379b77294e35c095ab26474d9b34bdcfbe1ef4376b60fa6990cd",
    "048b6bc02f04d4e74afb89260a63167af293f6f393f0ba21fca9ed95e76bda35e488a5e3d0194b4bd18c14d445c588ea93a39481537502b233efa5eb16920505b8",
    "046d0e923dcfd9b89b046c8ec5ed0ec2d29663863b309ce0f40d316ba125d2440e173ca0508af3555b21b32b086bc258d5f73568e31be402adc5e9362886294a78",
    "0420b4acb9246ccd8557c2b0cbe51f39d2bcc7ba04e14ce69ca59ac489960c2bf4391240ae79976a0a96c8094aa9e9f3b7b174694318c6fdfdd508c1269799ddf7",
    "04d05313ad46adcbde00d6563ea1618c6c1726c4b3014ac37a9e6422074c4741c108137f1c5bd5926f0c1537a6ea4af1ddff6169aa15d9f03a966dc6dee0e67a01",
    "04bc19b30592011edeca5dcee1724c49a5e065cbaa6bd741b12224c5fa849913c2255c9d364787316f3db3e8a8d3806654dd95e0f2f4e99de3c8aa11cb1656f600",
    "04d7e8ebc03d443d3c8520ee94bf86ea3cfc2d697886bb57910fc3dc34842eddebb35c4f83fd32ff1009c324dafdd80cd4ebad4e5800f4fcb60422d04b55a5503d",
    "04504de29b7558ac4f951a93d5e57196d9a709d0188bb89a77b4037d8e66112160218ae1721aea6418769a1889528a88bffc804e1abdcab13533438a269283179a",
    "0431f84048cb533c54a27523d1201052ea7b8b08d9a213f788e94a3ec54deceb925eb56bcceb67266e4928c8f257ace1a19973bc4bd2626593ecbab2607428b17d",
    "049ae4611ffa76d5b4144f78284d2a2409cf9b4165f791cd04e69fe78cb22176bdfd41e28839b3da0e763df4d60c1a6e1a9ed6f60777b861cfd2c6625e03433fc9",
    "04faa30590776102994e82af9a2d8431d53bd46237f784dbb71e411a3924c4875cd4f00fb2c342837d7632bd3e678d8b69e9929fef5f425c484e35ba6d5457e0f5",
    "045ac80e8fc6528591a5f80031aadb9cf2a8dce922dc5108d751a763e335d04ea67280dcf66c4bfc624693a029f3283f60bdb4b9279d630e2350d579dab7c32e81",
    "04bcc505f899dc2111fea9e51edce7167ae4e5993e8e4ac97f09b0a76b517631ddae48acf6c9a06a9dfc04f4c183c7046831cfafa77a2a1a219d9c97c9d066c94b",
    "04cf6ec3b28547356349db2b02c6bb2d534dd1961d79d7eb56296c9203c96639186e65dafbaa814c5eadb3abd24ef77a3951c8829a1c251877a5c75ca3bf958b2b",
    "0416a0b63b1848e3f9649ab42f54056801782337e86d8c3915d4316ae26d7e3d6faa1f94032f457a29f16c2bf0723fd8a346d67a2e8ee2f6a3f182e38ea232b1a8",
    "04aa5ef82e89489a0f0257ce4faa66d5e934950c17bb136c5a6651dd4af95e5721ded106529a25c571fa467e5b02e2d433693f342df88b1ff1c8b8025a7fc1a74f",
    "0409249a1ecdbc0f6ab5ac39b142e5e9d9769fe7c30128fb0f6a584090471ea58de920c319a34ed0eb15606bf217cd23e54f866aaaf5460c2e8a76e1e3928d8c8e",
    "04cbebfe4ca052018ce8ad175ae7b5e8490ecd167adeadf19b43e5c6e8206af232febee4703daf24ddc15ad347aa2d572083aee7b5bd004daf8997792eca111e6c",
    "0455037050eb73eeb01d781a6d86832c24d04c1ad4cb4f7b515cf92ead9762b10398d39689d57b1feb9ef539654da0b6f5ae297c3f68d08162f0e53bc54fef89f0",
  ],
  pink: [
    "04cb6504ff0b825009d19a0b116fa9f8730cb8df1959ed365c5af9d71090e6ee38d7dbc6b2322b8490046ca01250f434ededae28158f0e6f956617a753177ba265",
    "046692f88151be87520b722152342d3d5ad6cfd5546936ea031524d03396e663ca36a4e06beaf2614181000012ebb75598ee3f2c766f95d419950a7b63f20c8b94",
    "04a67b89d8096b212a0f0a16c0c40ce0a29f55963b4adc5f81ac7b2ba77ea543ee36887fd1a61f39b91f0723848b0d46ad610e3b4bcbcf9b44673182c28775a971",
    "04a8015a409dc2836b6e22555e712e97fe7fbbd3a9e09c0c684c9f8fdb204d2e967705ae14920df1a154044cb138e0772ed68640cead79a442e18e508f0178a692",
    "041163d2f3b2ecf3f813186f3f2695ff257ea61ea56f5aa553da3a4a1a6f202d099374b92b36fdca7938865e82fb693a10b45e0b6e11d10134aee7a1cb140e4d24",
    "04698112d067b4b3f7ea9158ad26518615dc5c090d48f4261158d04b61d02ff2013c1d8798682b1e513d29fb043620053b3e7dc4d979c50153967290e3911f7b2b",
    "048f7ebbdbb936cb14ab15fb7149b7edefb23e33b9f5d788c0ea65f5fc850cfd649e6a3a09412c711d9cab38778042a93dce8840b87114a036a8a9f734eb74f32b",
    "04e9bbc2345d640fb83218a474ea0f93dacad568ab5842adb520ed0de3782089744bf5a4d343d5ee0896ab674e0903c2539ea12bd904c44d67135740cc277dfe37",
    "04840af7872f28245043ff3d6e0bc6940d31bf323b697bf7248f309693d49ba94e145c3b94c27c1c249864421bfb17690c48c15abc719b14745936dadc40d82289",
    "04512f73180f2512e9377410ddbac257ea839f75a33043c9298f37d3795d4ae583a4e98167578688606888241866936f90e487a68dc99f71b1c0e2ad6921a43bf9",
    "04ed3ceb7d3eff59d0174f7d9328c5a93d25080ded2ca7f82fd547f024b317f048cbe7c3209890118d30b38b943c3af93565a1d62d01ef8cd7076543ebd4303015",
    "0406291526eb624339e4d6adf1b933f4d12a4c87d799f7dfa794058ae06b9f936b472d2bb2b09edec909a132f80d413b275dccea54b82c0a43cd95329db78b189c",
    "04e8cd03cc50c68a00573f0bceb029e6af2b579ae61738f42f835d1c08c15776f52a963c3153a3c2292840b6229c4a6a7f506e82816bcdc39c154d46917ab8f76d",
    "04e580a17b0f411dd1d71ec50b61d86476cf08fe611cbc08e57d12d97748808845753a7838f2f29f4a6b634982bff6cc12a5c8df97d08518642fcc6944d18cb4df",
    "04671ebb145cb0939b81e5542940ef5f5a67f26afc71c8d3a932a89cf08a4a4ac7d483544cbade9537ebd10ff29d45aeec2c47b7d35621d07f47dbc1f15eab7872",
    "04afda3a8357ad9af9581ba9d762a1b4dd6822203383c73d34ea41a969108c424c66f3ad288d17ac0402952b6d9481bcc0e4746f74d4ae2d84ea7f1e0a64ebbd49",
    "047185588cb357b1cb70907b78e542a37ef17224d7f75c096d3c2c70e15510f3e7b22a1ec397eca49da6f835fd65ffd907c4564385e6fa61eccca84132a415dede",
    "0447903d598e572787f0ffebc0483d9b9d2b87758bba9ee6af5d888b567e7846c3288213ef8424127d4f096fe2c94d3e579a8532c3881899f4f673a1bc465bb40c",
    "0432fbcb19e46628f5cf0b745e7c02e4edbd09de342ead3b4e2a278810b51341bb1e429e450c97f3504980eb0e49c3d74aea85ac144f9069201465ba1f5f143346",
    "04ff7a2c8a003a31a3683bf0a043db640c17ac71cde522f0333555882e3730956671b983aad5b8d99476cac91b057de77283dd148b45a6868f81946d79837891f7",
    "04840c3fdbcb0c0445476929962a6cab12aec4e72addd2b6ff68b0f4ec1c950a3a828c7cf16f724363b07e37400c73f6da100c16d1dc39fafefd2286b288888cd2",
    "044e2d5b04fb44385e04df423722f64ae7967cf7c356ca9d038dc3c8bd25cd5b7084c4dfdea1d0d1a90ba5a7a2e7996d428235d4aeb60d9146c3cdb55a8757fae5",
    "04acbf31e62dc7562845e3b68ac5f8598d4c14f545b852b41d8b703cd4943530be7911cb660435b3eed975f103aa3cc0c58ca3bb3a541ed9f002ed1f5316239dc8",
    "044ce784226a47016ac4351c135635b98d205be17e7196e43723e73f83826e86c32875efbb37d7852d35dcf29b70fdca6db66810ca9ae5df6f25c4315805be8f95",
    "04fc06177e6dd9f4ba2f882504298d3f5171968ae7132148dff8573a57f2aaebe0fb179b572a65c7451ae014a735acb5e3cc9546018ff82e5242bb50f3349e4ec8",
    "04416efc4d21bbecdeb5934a470e30b4044655f4644c540a56faebf23abda113412b815d03a19599bea8e7b076719d7c3fa7c14ae42c3f65ccb371e50eb0224717",
    "04b28f3996d55f32b24bf241744cef9f3c41d0fbcff3fe14ee4a3b071016adee6096f1dba5d7835ec9e3c203f51fcea5c26d2f31a65c90676c25142fdf58fe421e",
    "04aa2f773b399660e8894f1a88ffc26ae5546130a0abf6b8100c1348359f38c82be725e8990f73a7e7fce86a27189f10dcfe55478444399d31abff452b5950156a",
    "047923c4d689ccda6aa21b3396bfc638d86896ec731e7c8cf03050ffcd79919091e4206abab1453291d69d7bfcbcf6c28d6cc00c8a992687e6988165f299eb1aaa",
    "044c83fc80ff424c941c71efca5fe4c68f589bcc67d8cd63e9384947db9670380e53312e4be54f82c3e528ce2f79c929b62117a2af00f3b61134029bf8c089638b",
    "04bc38680e3504558d1500ec11de7a7cd7062ae43abefcb021fc69e9af3286d5a53e37d2ac7da53f3a09cf87165320325648a0a87d2a5a8bcd872c14191b0a1634",
    "04890435f6c6c7c0e2408ede3aa905985bc7a84ce6f57138a29e938fda42bf8e70912d62691a8480180c2ad148c5d4d09289762c28191f7cd6f8004d0b01bbb6e2",
    "04823a8e5d38ec980bca612bf4234e4998f0df5dcff1b36b130999535e189b2027fac8907b218312b7f9715d38abdd0a8114fd9452429ac88b58c8ca662dd15e7c",
    "04b1b505b56158ee62edd5296591851aa196a44ebe832897e76023f18c6242dee0492d06193cd71c822066f2d369735662d03b92cac18f5e9926301d828f8914f6",
    "0413c473f4799fe2a8ad7e1ba8596736a593c6f664faaa6b14b67640f0446526bfdc81f4a7e0dd15911ef33731e903d9390b263a0d92fe2cfabfa4cfee80d82cf2",
    "04f115159ebb1c2e35f1ea4defed75b106e76581819a394407b7f16fbb0c07c88de21accd068ac4c9d9ac2fae605be92ea37d9f97dad0e32412bb2e28516718a9c",
    "04d68d158179d0b3e908a0bed954d2e78404a8453241cc6de23e77b670636f41400a32a8e5a2f3a2f88eb10ec1fc2c5697d7da86c6da16108acab01fcded4cc173",
    "04d06a0a124bc2138225a7b3fe32c35716561a1dd43e1df84ce81ba0b7bc729d8c75a9225c42b4aade0799f9bff39b0d63574f5fb283fb356c1b9ffd03fb1c7f55",
    "0415aad2619500fcbb573b57c45cf535e0c566ace9f4e8b3d05b3a7c42cebabf0dcc091cc162770e9ce7aab9cfe5acb32ff37438b43daf2d64cf3288af0aa13bd6",
    "0416ce3ab7fd6a528913e030f8691bf4735dddc7fe4b719b41358bdfea519170b97c2a41f5498f2cfd73856141ad7b583205e873ad46441020cc8fcf6145c2b124",
    "04495a53fa48d4389479f8c9898a5c881fc821d24b6a7c87a33ce002abaa29326b54d5194a2a9781c332bd354600f5c42cae97f0a627ecb3c2d0f0d9dd49bbc83d",
    "04dd1d13c510d24f17b211c03cdfff7890967c50f36cfe8944aa205de614a0d4dee4331846421d25a1f3bcdd3504a73172006ceeba0973e6344273dff338391138",
    "04632b1d363b9805286d77ab34aa313053c49b001bfb39f2e09adce6160867f7942df489197f78591dda5c2cf068fd7eebbcde89736255689fa214d45621d41fdb",
    "0441c2aab63545556c1aa98b4189a7f9ed4454863db6d119e928d7ff17e473c572b47a972b4ca5cb2e3b1896dc3d7f27f730c6562dd7bfde57526cdc588f820e2a",
    "042c610c3f2a58b4fc6cdbbe270f83091f3d1b487810ff8c3acfaeb879a2016444bcfd39d4c637fd3e86f6ce62c6198e15d47d0b6ec78ddc41009e6ef090258b3e",
    "04d8d645c4c5ae77b5dab04a986090cebc135c4ca9d265a4e17df2e31e30fe99f9993b9b1191fbd7aa0bb0ba03cb9c3bdd094bc2529ebe5489792b3a56f80df2f3",
    "0484838633ddad50ef907d60c6dc53c431b1fb9eb743a4e879c83aeab2b31685c5bae0852e7c5a29f650aa79af061fe2212cbbeea9ba7d0247bbe4c25a25953f3b",
    "0459333a46a1b40b892724ddd6ad789a05ba526c9cf33719d18346eeb899a4542d686f983f80a5c1ebab0f9c8fd7e5eda74f205afc0285066f9108e640cae52837",
    "04b18701f0427ccdc926190524b768b55bcf766080f8e264c48a24bf0174d06f8a38c0797624e14bf2e2858070ba0687081602695133e0f9dc50c163a5f8bf1a51",
    "048bba7db416f7fb27f66800e0dec13f8c0524a5909af617cc5c0822387fd1711678ec5fcf23579ecd752e0b7609ef8af1ab83216b21bb22d32d09be0c3a44aef0",
    "047f41ad826ab114551677cf289dff5647ea87840a8310d4c17addf22c56060c4a9e9591fba5c09f7f12ad112d3203f75bbc28ca7734d7ee55193d1cb3c45eee6d",
    "04eb3803be93e917cf6a1e5bf2396476d14b61cfe24fa34e05c2690c814666eb6140350aa3d88f7812148885ebaff54eb732109ac69ff926f8f38f902aaf233ad1",
    "0477d7a8d5bcba6d70f3b4bfe6420cfa2a40644ab2539342da46fc876a55f672bb5f33eca574881c043eac675de1130aa8cddfa39381a395e0d1962060c7efd501",
    "04b5213e5536bf7553699a574bc5018a73d6578649fa507ee9f976acb3fc875c2bd8d8a7458a5e7d4c38440e9b30d7ac24044b43ceef9a31a357650919b7062cc2",
    "04ca2f0857d1cb6cbe74bdc136eed5f6639d26fc0395fe0e185dc2a7317c415340317e90a635b06049d2b3fe4d9b9923224e0ca7f9a7c2319d3680111b12c01707",
    "045d803db86b10764cb7f2070c7ab4cf080aa376d98808f615107c08b3b8594130936a2033769bb8e44c9fed9e6f0d8a28e0d6f1e2a588a6ef8b74a7685f689f60",
    "04e1a60cf219cb5917e38db617d1c350d3eaaa2a8298f4a960d660905c4e2885cb277414bca6a3abd7e6a6505930767c5b4dac359eb87cfbdfcd005e2ab040a287",
    "04bce745dbfbc397daa8e7242df6f0a20844ebc13a2029b48a474388d2e9e1727b390a5e490eb01620c37b76180bcd5ca40dc3d95d233d8b56f86c498afb68ad2d",
    "04244d18b2f341affc21f6f50f692625d54033b3b0f57d25f7a22bd55cc6f4c6814e241680bad07c0651c86d2a485e192e76d2b2c885b649a7cb590fd853880067",
    "043d81ef26dd266959ca0b69f17403d37b22c0621fb798a24e280f673e2d5c5b9c8ed8103bd3ef5555f16fa3d564d8c8c3575e3dcbf3d04f3d54a149cd69d08e6f",
    "0465a93e2deb975a39892e832ddf6fbbb9e6d9618c586438d72245a83c5d8af1bfda3441fdd087e4f644a3b797bba02bd4f5437553b973992171e4bc73f55fa4ab",
    "040560b07c8ae0cad93299b9ecb33f06c220a2c88cd5b74b5ee42075cb35c98ac10ba6ee5375e6bbb648459bfdff7885453457b6366785bd9e135c00b847013158",
    "0478f3ce157d11dc99b9728a67f8c794a26193e1e843442f27de8c13833246ed18b7eb1f5527420e26d909a4f746c6b432ab0a364d553910058dfa0f92803743d3",
    "0411a39d9c8856cf5939ca1c424217992e52b373581ee5bd8a3b7c41cb625e89424d66c6ea4dd3e1aecb4c4787ef279a124fd666c4b4703b1dbee5f396bbd44080",
    "0403ca9cc380e45da4d14fe7442d7ad3a7b274a25d0bfad732515308e6b2fa76cb341c41c02e7dbd9f3008ba1001b8f491d424fd8a01e939a05ab32d71230fd91e",
    "043a2018c7009b8c599d22958258264194dae46e828c094d70766664fd1afa09fbcfa7a893943d10c416c4b392240e43c43b86ab2d803a2fa0bb3c929a5a4736a9",
    "04832e7967c3830991cddc5d11e640b031b271fa042f10730eea8f3bba573a33fe0b61710cbfea5e3f526f1389ceb4f34cbd80d2b19d08de32440a057304ca2478",
    "0467823682563be1dcd7c4777b648c3e814edf5a0cd005e1a69f509bd9cc2b3471038040e84b230d9bdfbce131759c124a3b7a66758312da9c3bc60f9196ed3ad3",
    "04b98f08289abd604f664edba850cfd7f1e9d101ff9deb9750fe9db37b71a6d5ba35a3454054fed6fdb566364c2ec69b6c4e1b605ca3aa612fb6193a47ebe20bd3",
    "04625eaaddb26fefca949b46407f76213c64d329e9d2bc4ae32df2cfa2bc508dc2c7f127d3d6c4a88a8984fe4803cf2b403310d5d3fb2c693db85ae60a21d6ee83",
  ],
  lightGreen: [
    "04a78803908821c384327dcd6edc40419dbe791f06ea229145b35add72da63e0c8c5d4649e6ca5390fd2efc83926d9a12e3fc753a0ba4c51efb86b4341649a4b1a",
    "0460d090c4bcfb73a230edf11e760a3d8d21e4b9244fa5ea127f54b1a53a3d715f3bed8ff7a6d1e494bb5101c9035cef365a38733d0ac5cd08eb3fc278320e3146",
    "0434b09f12f88f144e51a62d6012b2c43237a91b1deca4d49f3bef558dcf0171cad55628a9488238b318d08dcc5b73ddb1e6f5e4f435a53e937cbaa2012958ef05",
    "04ad8be1243f3d953d6bf88518a4d79a2edb47fd0fc4db95e26af654237eb211d3f54c236020ebdca161206bc2bbcb64bfbb50800896dbcd9de56b3004146cf216",
    "048f675d329d6755fa0c20fcdf1bdfae7aef3925050451f6ee015409dbb30ee93cdc42a2b04ac064f2a186e9c3f570a31254a85eb553e0d41381373f5a098d9aa8",
    "04200814ea06e4442d6a07bef9bdfbc2f62004b655e692dccb098bc45c7367612897d5e2d2222cf41dacf48d99640317d0151d30c74f62c7a595c3fa501c70eab1",
    "04a4e1527f35fe7436781f555a0d4e9d577769a55865857c8866af0c0925b66d8e67b4eb642204d02f97dd2d3501b22054746f8a681cf056a43e62a114029d4081",
    "04f959e39a93915b02cd772d0bf4737283bd3296654ab25c2e6de3ab29d864a2efcbff5382d3507cf588d846228e641992dc6145de54747a1a63d0158a419858fe",
    "042d781fef217fe9418ecfd43b87ef863a292715bd98c4daff73d496662c8a1539f2f00781611e2ea874678eb206e642acf1f378d665f3877305af9b9860d6e96a",
    "04fe0c1face91bba309c5a29bfe1c451bb3634c1af1d176f3b015be81725981e298d6b67759d106f532c464c664829957943c6add8faed87416d829d59aea02f09",
    "048977b2bcb30f3c59b002ce340a722a172f4b3001292e94892da50c9646d8c469fbada53f188923b03ea1122f814a1b203790c83d09aee5fa6faa4216d34d90b6",
    "042e2d9f9c6d5eb3581709f3715cdedf73e8475151dce5a06fd87c866ea113e3409362497c00bd623c62eca0df452203976d60863e87778681b88f22464b6ab74d",
    "04586bd4ea6a570611f805a660cb0ccc6bf36798431abee05e4efaa716c87bec8cbc43fddec9f0c49c23151a045a02e1c639ec2fe33bf6357479213546e73f16ab",
    "04c84bf04e9c70cd762f91d7cbc51c26dddf07f266c136376be380f04cff0c85168bff5bde50bdebc1aeca7f83fed72fa3e4380a5078d30025226df650c731ad2e",
    "0430c16eb138f784902a25ce39679c8f1e8d8dc6874ae1b56937373243a953b13e0e5c5c0d23c504961a8793c409e4b5ab0fa59b2261e9378fa4c97d1b675e00cb",
    "0449d9a38a428a418f9da48e81d2a58b91c20043dae4c8d08f36e43855b1b2d31968fc41145b632f1b1c4f4011813af31eacd2500f95a875740310e7c8218dee2d",
    "048d98441d4b60c73e3f0dd5e4741fc63a2802756bccfe1a95ece3e2e3456225c3b1397ab266c3f8787e839c006c9da58fad34b81e0ab4d082b917976848eb0ead",
    "043ffbbbf2a00dc833bb12c2da551cdcc6405eed2211407c47bffeb644668bd4211cecea7dcd7a615e7bfb8533e9cfaa3c17bf6386e49a95d1811b768c6bfd1279",
    "04366734398826ea492aa7e8ee2ed7d709c56a7c1e5dc44a7ea752201abc9787f6f49ff50c7b29698e81ea855207ee156e5c9f7ace4da0d788b737ceec52356cf8",
    "04156b155cd9cfdb85111556eaba24312cbb7a4423ddc7de9ae37dc574e4855c21ceff6de7b24abda5afed880dcc87e0efef5c69cae7c8b519d31b82d4a6f8a861",
    "04eee1b7235d61b1ec9a419af470e377e15b494972ecc96ee0f0ee636b31d5d9637278e003709effa97fdd0425e4dce1c37b503ac36d99dc602ae7a2229d0f270e",
    "04c1f65729c6ebcc8f3c8f9a417f7fce711d2221445c1641bfc86fc914794bc7b1b24fa211f5a4953240a5a0ff00f506db471c68fa824a8b6f2d9144e1faf15b79",
    "043fce00080b68c6889a1ee28c8ba4663f701ef5cefeb7cc9636bd22da10e7b282dd2430b03290f8bcdd10455170eadf82b29873c7fd97e47079ed2c56d81968ac",
    "047f82d927543ab77db3fbc834749cce8298a939630c0ac047306a6424f01aecc5a32fa9840a736c4807d9d572a1172570ee9b12a785c5b1d4447f8c2b4733f3f8",
    "04f878ac195c3af20b26af7e00f8da2dec6d83c9f6b9b549d610537943474cca17bac9d4fa3552b9876214c444a8e5cf642b1a324611f4c459dc4e5b98aeac08fd",
    "049610cbbc8ea326c27c8bc4dd41c4cc81320f50b7c963d9b5d08560fc8004a4c6cb084d33489e8dc3ffbf8b6cdc7cd00b9183c83b1927a8b0bc380270dce2240e",
    "04ccca72cfb6dd168be6780c20b41dcc1b0612266d1be246c46c22fbe09c9ac5ddfda7c37adc9dd65c44a29eeadedda6ab0a351b89a29d8bcdfe0c481735d41b6e",
    "045d202675fa9990decb68f16a12c05d031ec7c9880e087a9d1c4a059ee9da7982cb104c7114dfe843e47722f1f554f93442dbc2992981fca31a343c0a1fa76809",
    "0448b37a21c0703351f1e9cbd26a5db5be836ca7d8e3be594b2b16693e452c81a8bbf618ca877cd0c0974ca50afcda8024760e2dab0c5575c78b0fbc2742af1c38",
    "0404f76ed6425a1ec620c28977af16c73eddef71c0874d7a965b33f8614430a2dfc1ad3d06249ecf8a4b508e5e4842685edded05fed7aa4d69f96228af7efbe968",
    "04b9282fbd63ca117b84e357ccc8d8f5a66da8bcef688516cb5a0985ae797a00c80f43e60877b2e772d51cb4899bc8eb20a657f181b855ac4d881b39e51ccca15c",
    "041a4207c44b414523be7e7b30230b3b8de07882d6334349324b59d3b7b606872b45b16d2264c9703b37bf23af6082ab9087df1afc1a014190b4f70d9245c11610",
    "04b1f532b9eb9cdb6128fc7b6da2df0b979664137a9737a705d5dfb67476ac0b65a473b36946fcd424253c5e86bdad9354052a880f997ab48b445b9592d1af8b9f",
    "0451da22f260e902de64c47dc9b057bd48f227daf5c8f4da7baa0a95e43498e7e9a92521fb3ac7941045aebf25c5bf8a60b0ffdf5e5f8b392b0e15464e224128ae",
    "04037e32bc3d73713c67179d6e33cb6f93c26f40b2d133dda13dd11c7afb4489e350daa10a8a9307978cd46da6e15928f8291b8f96c28c5622e4c6dba69f0a49cf",
    "04ae01834abccc6ec7ce8e8fbeed8616b8db7cb3f6639d37e7bdb9f416be29a219c0f6015e5721da1e33a0993263e85095776560a171bbd864d5178590112ae121",
    "04fce77992157be37049a00a76ef618d3231d8662dee55e906952133767b19a91be2248b20c9f1ca6cabe436c0d3122e4ab97068b3d7fc289b5ed68371dc61d228",
    "044f75e588c5314fb6bdd72202519e6daa1a34711573d8db20fb31999a29b870db6b14fc678537a7a6e2376c3c91f9c560cf661fee8e4f9b0bc6cf7ec0cdc55696",
    "04a769b8cacd636b4fac5200841ad768f6c87a6e304d72c55e281f2a9e1026efebb562958df9c00f77c298d8d412a0762b411fd86c6d6b54643856d120bf8d0af9",
    "04cdc15383bbdccb18660dc2c09b88171b8669d35942196645d1c669e71f266a949820a2951ed3e9f32983d9f2d8f1efb8ca62db2a95988cf4278900241a7f115e",
    "04e3177ec2a83b257ecd151fa5b454a8d0a0a4e1264a67dfb0d6546e388290dd8923055c0c366672e210053249e0f4e27b5d203fbffc41a736266585aff59b7bb9",
    "04f5a57541e200ca4ed9fac81c83698e648e41526bb8a43dfbe1b5ec3b78397e09196c43e1153cb7fe5483610edff5689de54327b60a072d871704c7834ada91d0",
    "0436d9cdd4fa4c69835539d331c6254c709c0fd9cdbed598e7ca96e7ba2f71cf30f9a1dff454d5c1b1529a311ec87df1b01ea99ca4c68ea96e8f9bf9b1b7b174da",
    "04c9b332fb736106268323d2d8da6760df281b634ca89ea5c7b2f8b6d33d5b60331c8fefedceb5014db82f14b1391d114c13afd9a1d0e151d481edfba3d4ae4c40",
    "0453e6fa3f32ca275b38fe0346f0966a795164a2da4386abd6b7ea23349fab0ba8d581639dc91ae040e08f9c6c1568185c844e5ed4ecbcfa43620ddcc1416e493d",
    "049884bdeffd9dd6b68e0861c857ddd57f212854fc2af02634bb7c5f5d05db33fe09a639503eea3b4f75b65dc758e6dfb3ce21418bed06f178980d82ef32ba3911",
    "040c65489f265582be56218629ab785c4aed8ae8951acc12aa2260822eacf1b09359e99fa861938ea03cbbcd3181b12e600bcbefe0af47cdf6c8e921e9e03c87cd",
    "048f315b2fc51f22778faa35c51b633a719d73623fe844f5149ec306713bcb95e5409738fd5fa8cae3b398d58d8d27abefd7b850bfb47a7e71758befbb0d6c8951",
    "0421951373bce9a23cea7e8bac09f697ecdc2282f122035cec3b7d4a39c579eddb823fe04adb8201f255c04adaebd1f468d50ecc478acb1f9d1e55f0a14c575b43",
    "042df18d930f383017ac822e8ebb7323ab0f88a3182248c1d0eee0b7b40bc67ae42a4879c42d35b7184d078519907c192adc8e745e557e7690528cff4bd5dfaef7",
  ],
  darkGreen: [
    "04790b11c520e6f27d18925cd0130b3b5ad60322d6b6168d43b082c2dd742e7b3c2f77a1f4970596dc05a6f3ba1436887381dfeefe3097c5c3e8529949c4b16d50",
    "0496b6675c288b57525a70675b53d2ebef522a37e1a523f34109c50583c4379531da4fc2d130d8bcb7f4e85e4f921e3195626681b0598ca2d8f6a6cff25005585d",
    "04b7814367685bf34b93a8351df0eb43514bbbf37a4fcd5c0a5673b12f422fd579b5d8959d1cf848c62429e34d02d0b85e34b8e4c8d50e7bce83b253501958eb45",
    "04f1b5706387e4b9a18bd7db4abdcf5fdb4459623656b8c7659591ff129f80c3ff3e9040b7c1ca35861ba75f05bf88f51d52cefd1cb75df6970f9664f156326859",
    "04a5552259d6bc64a2390400ebafdc9f23736698ecb9e401f73eae6448cb1e9753b6f4f793c87486267c347cddb66ec2b1334fb2a0a1f042ea20efccc6012439de",
    "043ad728c22addabb0bc8a040087eb558c500aa2b82f6a4f61a684dfb6332b53b4d7646c5386762e9213410b48fb6d90799f048bbee15aa5295c352341f5910d11",
    "04ce00487287ade6734e88c273fc94a2eaee9e50387b459cc73d3287373fb43cce80bed399c61f555445769cf3bbcab3463cbd97785e3b579a64b84d818d356c56",
    "04087d3d56c7301c3b1c8e818592ba95a56dd07887ecd219a35704de045af308499855285adce32e323cfc7b27c85ae751cd8871c0154b61fcfce28735cb95c5d2",
    "04aa572b82119ace2cc071c075de3fa573086a3437eb24f7a5852717d779550a5af497f18ea7d8cac984003324ccd60cb874ee267d12f7fd3226d808646d6a20c7",
    "0459a567536b419d5cd33091a52b162ae21062357d7baf561f3d38fd450f238adccbabdd664d821c1d96668a3d6e2df04a31321eb768d31dce9cdf4e9833326441",
    "04445548cfa88618c965eaf1818096e7a1994014544776446e11799a70ee6a76c5f3b24ae8e18ffe4cc768d2d93f5227a856a4c1fd83e45145e141c839e9ef71e4",
    "04d6e7ce8338b70a8de22eae3c71372ffeea73962036d904723317747e171e8b103e39cde71b1da7a8eb21069da2d0e980061c8b4900014d32f5aaed8bf4bcf8a8",
    "04af172d185dbfe5de3abc4160a721e886c01a11f601e6021a3d7f1ffe2132657975a7cd9bf13482c6dfea5f5074b8199ffc872910786a4452b340c4da8d848674",
    "04eff2f22f31ebb924c56f897acfa64b74d215797a64c70df44848e063980401d81d4068212f634ce9fbb70ae7960cff79fa23861a0487cf81f97e7ac11097617c",
    "0478ee540f4bdea3e30f5f4b46e469c0c186acd630195d03e49f85d3b79f0f61f5ff283ca562c18ecd04e6ad4796bce665f765ad7719237dda8f7aa82bca907a3a",
    "047ed9a8cb4d4f7419914ccb330dd2a97dc8828cc7f30eeca4603a2737bf04825ad8ae27dbfa480ba51193dfe6017a0706486c158ae718bdcef36f25367b40235f",
  ],
  blue: [
    "047470d00fcf8d1058ac44ade410bab531a7dd40aaef18450fdac7dac6f59090ebd22b4809db1b7ad0db56c64fa20c5cf2c0204f0a114628f8ee8a3b540ef49f0b",
    "0463c04a09c9d17aaa4c2f9c19ffc83ec53580c5f65fcc9bc3f4e7e40e1a89ba83af67e126811913070dde3569666c872134e354330d7b9716498deec8428d82b3",
    "044c2469ff972d8f32f1addce4c1e09efda8210e9cae384f70f821f88edd5a1863f59ff823b1491d5bf46af7b7efcd500983feb400c93b9a71baa9d94d454d7387",
    "0478ad824decb140965ae118bf47eb5f735f18ae7b186df7f94281607a88e71f64a9c5b5fa25edeb5046b9dcf7a46a6c81476758e23c3c30bbc34339f03514bb0d",
    "04374b1ce3fafb3196e8f05f26b586b536aa84177625b300e9c436773920f44c5f1079197a5286c8587bd6f9f3b256a1e707f724fc211bb984ee0609d6fdec4210",
    "040b095d6ceb2f212025bdb6abc7fbca385ff3d6f092d8b18c3117d02d82758d1f9b8250336dac062197a425e3e220570a7a365d3afc3e9b5c52f3bc68ffae875c",
    "0420e97d41193efeeb8a886c1bc08654202e5c3c47832f6c1856a85c723cbebef7b78a68ef0026ef212cd18c9161b0eca0c2336d46493e49350d3008e451b8d316",
    "045089343f8449b67c70092b42c497d1ec944535a03c41a236b57952fd3ede99a93702e564d4d4166d2d0cdddeb748acd66b48164dc12378be28d40641ed76b295",
    "048e4029ec1a663ad81f61feab4cfa9d1a6a61816b00139cc8fa41dade9fbb4a485adf660929efde7000084db2c7b122a3fbdd472fe8c64ac359e3c19c2ae150ef",
    "04b9c96a390ef328aee6561685d3a4b3c5307b1c8fbc86dabebd039045a9a9f3ed764729b9a4598b4a5b076c3e35d6876cdbdbd8fe7b430f296ab7c5d898534dbf",
    "046f66230adb82e11406c73d605104d21edb492d3df3087901964c23acb3c58e6a99461f0ca9c26c9d23f2313b2a0c9275dcb9bd32ee647353546ae10d713e6ccc",
    "0402de6973c9402ad2acf80aff22eac66318e7e72b0ca2e6944cfa1541fb79aa8a2245a10d15f74167164d62579ef60191737903d249cc982e89c1f93acf1c34e1",
    "04bb499debcfb1b09547eb17887b727831ab0f475512ef1c27f56d4ededb0df76ce53ae3a6180d2def0ffeacd53dc90b248652a5e45f1a819b20a0d681f71ccbd0",
    "041b4a2a0383276967af7f60a64bf0724ce8278f08a9a39405103a59af0b700b03ba788bf0585a895a6e9dcca31ac2a737fd30232a8894a6ee709dc4c3e25a46c6",
    "040d87c23a1586267ae626d0e055b3992a674a6e9ecbcd294feae235492214759f7de4ee293d1e18d68e67ab8a9fc140438912583cbb2cf5bb8708fe44862959f7",
    "045c473061f069b08d83ed978a5c4784f2319a60589348804e5249dd3167698ee9b7da789d0aee4826ccbf2f9cecea667affbfce15f4da866df7d4db4b00912e2e",
    "04b79274f27ae1aba4d7bbae5f47e1d7eabbbd9aa483a4d4470ea4c20026dc1326591083dc20b969e2eedcbb624e740e6eef9fe4a00b415bd72793cffc0014afd8",
    "045e61ecf0eba805f168bc821ebc2b0a3f8adc9f1fef03c823f314b25c620aa57f3579fd4ace5bbe3676ab3d7db554658836e903493c2875260fde080d107cde47",
    "043c078e6ca9e0abe20b6be5ec660acf173f51dbbda15b2b285a8c752d12f729041ad4966b6c98e18291021c65f57618753de7812272aabb364952c23fa6adef2b",
    "04c275a2d707d0971aef350aeb31ffca2485b8328df368a18ebb09b5bbcd8c7f9bd26996e98c2a759d50c3d52c1748bedea5e19d6e433726b7021848b8b2128ca9",
    "044679948f85600d60898ce088b6c603ef0fccfdcf2c093b38d7de43aa9910d2a20776fe2afd9119656695e857d7fb9bb0398bd80aba173b5fad7a16b940181a33",
    "0466a23713503f0bf387d3c915104411fbc25790ce0f8d6065019625f887218f0363dada98ab68e7545f44f620453f5e9d89fa67a78797cd720236335eb1c7fe56",
    "047bdc9363d3c92f3b7e33c2642a3284d2467d99870b2df78a4634de90f08beabe1b786e2bcf488c04e1a0f0b388a951d37a11b4219c8efa6b3a3f688bddfdb0c7",
    "04fbe751b5854094f6708b4f3347b2043aabbe79fcdfe6563c07c11a139d102076800301472eca06ecfd8b5807894d7aab0c66c53febbdbfa96bc160751b0676ee",
    "040c225eb990898ed71e3c9e6c87e0f955960549309d92297d43682e92490f188142738831b1fab696b3fd78684c4a869a1122c8caa316269edd3d53bc814eec80",
    "04e6aaf73e710376b1189debe6bcc4c1eb6e5c0f1fddd8ec13d611784f61f1bcbb84d2062286963ba628231c45df137bba08e674bdbdb8eb1f9728a1082ec16ad2",
    "041c515b586734dbc74da9e86cb98d76aa19a084408f9aaddf7b8e136e80094fcd46651e4f9ada599ecbeea61ee363b953bb318934b596ec85fedef3f3f33d848c",
    "043a1804680f98c8f7519b27e0c533d9abc026a343a58aad53c7f3de95bd0ab0f218f3431bc71dfb464bb960c3e67fc7fd189fe9522ba627c688300f5867135165",
    "048a12e8634e2607cc5ccfa58b8f3f6c5ba894c067a5371362f41c10eb40c6a07c9148ba6983f41d66f5c65d1b1989b7f3b0db101c49b486f381378d56b5035a45",
    "04edcbd42ee17b82812b6de436a67b9028307a37417049c8f574729b378bd4fe2bbdddf9b0db8b1e79b2320b118255425384bc0a92805b6791ffff55e4a634b035",
    "04fb6ee82739d0b1a203b7a80af340353fce3c43ec54603e2507b6215567ebfe156de73b0c520cd15d425597805363c02aa82ea58ec7c4bfe5c9a8b13b92cc6f0f",
    "04a0ab9ee2161873b761d6138686861994a1abfd6522cecf378b587be6f3d63fee9dd1cdecab44251284b7156b998c6eacb826dda7ebf2c593abd84006125e3c74",
    "0423eae10ca92a0e4b7ea4699c57da4c26a68a9c8535b1a09901bc724b2afe1272a891de972a32dcc138bc5ae2b7098ff61a6a86612c95c2a5e2f62ae1e89ce073",
    "044be42439267f2f58a54a53ce5f4b30a9bf70e2eb7d1b35c587e46be07b87935027300203bec90e67e3d41cb6471035ed1d27d173fa1b332d84e08cbb591c2334",
    "046accccf5b12c438fd1dd1d9e070a62e08d9127b3f80d41fb6e3c99c8a4718e9600c8c6c4904e08b58fe2ce810d6d63239828959671044724136f17fa238e0c59",
    "04d3b5a8fb4011c17391898acc1e352c37978c03fb1c57a40b4dbfcd4e9345cebb4f413c1b0cf2f7e595040a155547d7c58d0bc0d2d093f6648fc932152d5335b9",
    "049597e29bfc5606e02908c6cd344eb33c847b52221be0a154e1518d36fa5c55582dbdd340c6cb5e0f0e46e7b8c3fd96c6f9df333faa1da218abcd4d52533daba2",
    "048bf605401edc7fa763efdfb2924bff14337167347b8974f0d2d014dd3ecfe00ba7763a4b77f497f785f3547e63f12689adea06f6686429d2a71bf8c916ac087d",
    "045ddedbfeee2a34d646f183491bdb87c3643f9a68d795254945a0f2b109eefd47bcac1858a29e265934cf9ba0189eccaacd7bbf3dccbf3ef15edaa86134c238a4",
    "04caf6b90a5a40f2517600f60b5f5de09fce6c401329fd08cc554357b97f612c4cf32ce1d2b2dcb8e4bbe983f62128ae2f7290525887e17381e417584e7d1be325",
    "0481e7940c6f046ec099a683db18777b602787fa76ecb700637385cc6214d5755ae497117728d259f99695691ac843b8a5dd11c57b274b4e2c986594a22a944277",
    "04abe6a17d5bd9fcfafa878e1a06eace9954addb7275a0e7c982d85ea964102b04b63b43e41bc5fb8b5d3040b647cb4405b705e4d80c1dc893b0e32a54a97524f9",
    "0476ed89c94d02222ef21ceaea88a2fc53d135b67bc2df2de3d429c5e7ac9576cafcb14b435231a3ca96b20fec48edc1477467c4059d6e0d1fa3dd768b9fc3d818",
    "04cedc453223debf96e8d0f2c40eb2e8d169a4c057289ab64f8a804129ae6bf5f736e3ba1df696f8200440393d18ee5a6756502427225619ec69301824a03f1558",
    "0440e666eccf37f731d53451882c74b1ce287f7a4da707f6c03c10d8d4c0eb444c99b0f15607b2c83ecac8908d470a15c252d92da2f5267925d167c781cd0b9c43",
    "04c6014dd8c93d6be1aaeb7dfac3f0bbbb7baf8fb2fa6366f95a45b1b38eccb75e949a1e8b2560828e664f41f3cee20b3b846ef19c3f2eb9f0ce2baaa526dd6226",
    "0460b76a8e44ef27eddf556f03764eb66f729afcac03e0bab39730df3820a151bb864f2c7fc808e0fe4906a8ef27c62b7dc1f2a06c2012421531cdf8a3053a40e3",
    "04d8fda9d6a5bdd6887658c2287cd93970c4ddff5bf4ac25174e74f03305bf81432a12f1841abc3e38db96b90e7da2355b451cdc4d8fcfcce79e3747705248f9ce",
    "04d22a0a570df886aead81c39242c8e9bae9c4e7cb32322e5fa83496a0f4fcffa5f23b4bb36df6ed749e9f3b0920ff3aa84bcc4f9f82332a5868d683b719f9db78",
    "04b7a29418777bf3ff12eda3186db592558ffb0cc646b4338346b59e9c71668c1ee1c884016b89bff25ba527a817097fffe1affac823b329d8345ca353b51e976c",
    "04b88c528bb5744c2d3349018d56c3edc72a683535181589f5b2d7248d3d617b5ed85760ae3c0bd0859a08aab073143ea2f1dc685b489c230aad8b6a3a887af17f",
    "043921650c7ff8e81cf686819f818fd359335dcfcfb9ff6d70013df636ae575ba799d626686ffc6ae7ace776995bf67b39e5918276fcac5a7de898203ce165801f",
    "04ed2e8a2392496e742fd8b9808ccd66ea3ad2f6a86416e93eb8a172f3fb0f51e993ba17bd19a621d276d1897ce546236488ee41fabfadc544006a1c92eb5284aa",
    "047f77ee42c3a4abe445cb9e845d7c5b04c196aa58f28c734c5b5471c8dd3b55a58bc699337886b6161858f2d681a76fba0b2f5f3958d933dadb076693ee74470e",
    "047827718bd11210f73cb67b8685a2a504e6a1e4c1f27730f84cb058b72c81ccdca0d74ce765af1db5d35745ca159470d92094537832275540ccf93c2c06ac6b0a",
    "04c49e8f23d907cd83d502853df4186c60c1add77390d2acdfec1851705126428ff20d1e8d49ad0b42f7a0b179a85bde99b54e6f8769210aacb617dd42717747df",
    "044b1ae01c6c228017216305761801fdac9c6518049a552269a179d2a9ff1929d481a74b626e8562d54febc4a1720c7bb6b1481f3595b7ebb77a84debd171078b9",
    "04b8273c3fc37a17d830b956bff6100f09744b61abc8089e2a48a3d5835c8259aa557c259a6449845fe94ef47d904eba163fe3131a83cdac0233a0b79816246bc6",
    "04bd0456b6dba43e6eb6fee36e636d9f63a2b774dad30d28038832e59eb2535b73185075feb2896a705dd51fccd2496ac65f474afba943eb224f8da8b4873816b1",
    "04f70e5691ce6ab8cff5c1c8a7060970727279d81c08d38c0081d56fa51c61a3cadca16efacb171865c091b0d55b32660bfa626bc9fb21911ace9ed8cd6a758a85",
    "04722d0dc275da1fce3ce832a7c7497d3e12d53a3f673f7b9951265e21188af6f853d961b933d2a2418d59be75c2dac23e1be6677684aa64ef0e6685901997e255",
    "0423a79e50f8d66ec547a6a51cb1d718f912424275dcfd48e81525b122dc19022aa92cdab13ee6b7e31ed274eceaca0ec35528bf0956301101914af356e226bad9",
    "04cd913192c0df93522c26e8179d04fc3e9647b9c5b50ac2e0cd607f0065d74ffcf4a0da344172ec2015880b7e0a1be5ec766f196a8a6ce3d8cb8993f886b13f14",
    "04b29cd9e6e53a5bb9dcc69c6369b57f91b9f8c920cc7b449fb46345c3f2a855ccac4e6fb65796cf09c0fa69fea4cbf74413a0479c1fbc47c493e7210637c0ecd3",
    "0469df6170c44ace467d5264cef93108d31e7ff8b970e0f9f142e5fc33c240933652d87d162b99f01d4eb9da37cb54c63e77074564cdf92f74d19069e1cb3a9088",
    "04b135619e43f632c89fd07e012f593adc3f7ebf6937164c89b4c88452c1f6eb534260df85579de4875ab555619865e5994d0a388542211ca1648df06825b26612",
    "043ac067bebbae69cc4d2c732006271e60e4cc36fced8e16095e5a092719bfba32699a31301bbd66dc22279547aa56f2f447d80e88bfb8f3dc038fc46cf2cea95a",
    "04006aa6123d9b38c46bbd6834b31585c57b94398bec9d368783274eb3c44c7e105212bef3aebc3d8acfed8b07e24a9e112d477938d177c4bd48db948bc9b64391",
    "04d88898fad924d6bb66fb0a7e9efd396de9cd7e54a302046c3ba8f6d95f1e49ffc9b8951e9bc6a4da81a5b0618d04e1c5e008316e417f3585a9cda4c2bed6e6ef",
    "04e947774a866182082b00ddbe0dd83e689aa17d97e91b5706bb18efa6239f4e6d7ed2eaadd46cc2ac81e916e6d6e6aca8c8e87faa528b22a7b35f819a05cd08bd",
  ],
  black: [
    "04902814e20463cdb31af86daaba3ebfacebde80105ccf58daf23eadb29da09a26be41639a8d7bf21654c53dc6cd0e6c5278e734132a187e09379d0e71134d9cb8",
    "046c2a9a3e36b1505e654ad415f7bad4572100243514e04db89c2bd290549744e8b3de00daa29ded89740a55332513846ef4154c715dc0130c174f88d7b56e7bca",
    "04a71c4feeeb174eaa5df09f2f2c31bb0cf41d71ff480f478426f29db41f4047fa017a4b908ed0352238af5bd52961055e66866c134ab9720fb44584b6f5c42ba1",
    "049f56900aaffe9ef85d69176d65b9c47f97988aa45195ff267f838537755b7cb8a942f086369cde910e3450c94153a09fe37d82c8c1be587d1626dc7b370900f9",
    "046ce10a6bb12e54e9e7edccf53e25a39c6db65dddde68f4573e29e7badd7d814dd246b4d3e73b94bbf6892c9c7840111ae9b80b0da055aeb279945412ef21433e",
    "046efbbfbf1a9d2779440b62b98c8387db0260fafc4c6b396e59054fd28a7b0a57b935e2af43842759c11be24c58203ac37f2b11a4a53bb3bc18f0c2256d1a1620",
    "04ba477295f325514d85223837d7eab39c6d2f71068889a6bbe4aeb5593bedfddc1f7b7b35afc8be5fed7f8a3060da31e814b58947ff5c19790c77c4cd24af1a54",
    "0435024fafaefd8b95d19a5ad3eefd0839885561bc672301d7413c455e415a688f76963b2cb20edbd0b8820c728509fc1f0817469078b025601f55c095a1eac1da",
    "04bed82e35653002f939e11bd382df8bfe71f5dc48629625798b70b0c72fac2c7cde783e9849f0ba868cd2d41647ebc1fdd4a1894ccd19cd16e440efd5e7422bd8",
    "04365826f048836e0e74dd0ab34af0c7d1dc58e0568cbdce655d29f5a9d6714fd2e1b931208590466c51949efc618e0974f82bf64690b674f98b2b0aa1a116bc1e",
    "04b106353cc1bb7cf55155415134fa99bf3bfe9e476aababd914be68f031371c83c748177c9ccc858552095eb5c49b556b1268c7dc36a63cda4c89538832595553",
    "049293763716274dd604c7bc213a6d923d20bab7361bef0d3562e35b81d6aac9551a59eb4c068c08017a03598c7db2120ce077f6447635c5392a466107865ac53d",
    "04a1e0ee0d24f4917951abdc8594cc0d79882888d011e5a56a4285de2377dc8125f21e9c42f170e5f472c52cb4e9b8cd12beadc3984679f2c1c38b3e64af253f8d",
    "04ccd89d7ee7343816bc33742025aade5b8370c5733af2b195284157328e435d14220a7f845f694b5b8b5ea389ca169c8e380c764da63335b37e52c31950a7f53a",
    "049a44ff659c83cecdff91adeb09ef2b86df864f856035ca33d0f5fa136276c2e85470ea4ac4ff86b28a5d2816fdfd4a3e03c008f9d895f99690207abfbcc01bb3",
    "04c409d98e35ce118c07b8c81e86483df74e6d8fc217bbd3554cf6e209bd2286ef1461172c2fde37c251f77fc8b37ca9dd9a9eb70fbb192ed8864eb3b1f62edbf3",
    "04f5d77a1f42db9c1b0c4dc8ac9794b03f5499c3a5c7c66aade0fb116a426acf920b8c123d1b2f7d9479933a49c0f64c21636b45af01b726724f994c6ba40561fd",
    "04620af1792825f6a4c38397867b1d5741448bb6c667197d176dc5a1c9878e7bba48c73a6e4b3a2162281380e5f6607c38a82cdeec83661eaa1eda79619e7a4220",
    "04686bb3f4fc008f8af23c8b677cf1e615a28d9d7a98d8a4065427a485e8e38a0c589b6734ae450fa903305775859f2e75db7a547460517d4d907744a8a9e7cfa0",
    "041ab0e0445fff29a2c3d8f1b1d511193bca24be2eb335b0988c212db69bc62ea420cdb7f6a615f6f3f9db6fa791d04a4f78f6823d70a6de56a1a465191c900bc5",
    "04d4edf8608d22f57646aac3d3ed2ff60ff95e9f3ba7e4b265ef0a972c3c1e62858a38bdb24f620402c1a09652f4f0e53fb40488b9dc59d841fdd352dabf4e60fc",
    "048c6406a91952e515eaa3832abb1127374a67425cbc4155ae2541fb679a6c4577e5ae1961516465c3ef2b216191413b04d97ab3c27c347b45bd02c74cfe3a141d",
    "047f878f53219f2826aff4be1f423e04120fe4ddea08b021eb85c4b51163d0c5392c6ec4eaae483d89cd105f4be63a951ec16c88ad0df989779e18c2770a89dade",
    "045042b92bafde86879b1b77a178096bb221c8abee9005d6de0e85faa64095dc0d5758bcc082ca7e75e4f8191168d6263e3fae34e97ac357e3bd9c1f36af3ec256",
    "04ddc94fb48a4e91b709bb226149dfc689fdaa0bf886559b7aa7b230a4ef2d26a9aa5de69461eca5934f838f8956364eabf280a5416396dc12ec0ab6ff990c4fce",
    "0450923715f4f3745392cc192cbe7d1d3b5e7fbc459c1dff5f27c2d168d400c44a4f75863ebbf00a060c13f6a082a34337cb132e4ac074244ece768f4a9292f67f",
    "042581950704f7ebf784b828ae6c27b4f0ece664092ec970b6f9920dc7570333076694e903fa4e1e8b8325b0e9a0ed596d4b36b3bce16d2dadac7d825ac7a8776c",
    "047a69d0354be76b40bd20a340e0b8c4b9bcadb4c78a4f1e738c2c8ae4f40fa27011ed80a5af0ec934edab887063b018479f2ed49fba548d6e0988450a84211314",
    "0443ed44f6cf66422dc7f408a301c0d1319b80672b375cde596f54f3445ac0a78fef8f863c9d8f155dfa5112db5156b17ad708ce5e8e4e66ed5d225fb3b6d93cca",
    "04390f1b39ed5f97f73cd566308684bb6a9e1e90fb8b282d9184f4c0289392ba474f0cdbae5694e41f7ef8d3aecfc605e5d12ba445fc4ecd85799892365bab5baf",
    "048b710512f5d5ce88a085d6393d707d21446451b5987c3cc562fdeccc1785a0f0cce96c74cc896dcca33eb333b573c8f5752e230b639e3720a924d83ac99e72ba",
    "042ce984bfcff9419456c67693cb2f920b534da7299c3ef6c7ce484c993113a84db8b3401c957455843b11f84c2d24ec54885099d254a4747f97958404964143a7",
    "0444950705237ed37b4b4a5a29d2f5ff6c8c4046d14e489a045abd947d1d929e02f800ecee2c46a26cdc65b2503b50b79f71ffd56451efc8ce637e30a4b48ba6bb",
    "049d0cdd4e4b8235a7a28bea3d153398795aa60840282c9bd096df5465f6474c60505c741156b3c366a483cc4b91adb0e01bc66afaa10994a728ca8f375004e07a",
    "04b7ca2a36d12c9478258aa7d22929aa6104de63e37c46f2ebe0aeef9d5a6cc8db629a7bf989bccc59a95429613439376f07bc9997f1ab3ba3d19712ee147573b7",
    "047668bab29e09f1abe4cbe9e1c54134f0802ffea1e08965c315766fbc0f7de85c498fafd873472de92b7b5b2bba2c3fcd2b5dcf103693e10a126137c43bca57cb",
    "04dae51a04766c22e38d9d9e53123df872cdafa75e76dd7ce31407e70163dddad6fa1581e0758ca35e42a706b0314107cc01780f7bd1409c670b2715bdc701bbd4",
    "04bcd8451046e02a81f6112602df466a2576c7d4f2586e02c582f05ac84a3ed16fa9d29bbb13b33b2b8e8db6d226929225952d8b84a63f9da295fc5f779ac705c8",
    "04b7af386f92fc0bb05a15049467606ceab71140c2f35cddbc88cd1f89d3d2df108da0a34a00a7c13b20e277ed5139d3c2d8bed66034dd0e3a43f849557f7677cd",
    "0432dbc450cdcabdade6f8aeb4f17871c43f1ccdaeb6b8d1b104c71ed2e60b6eb2fc9e612f72ae93d3e0d5bba19e485bce299b68cac2bd547fc39b18c68125aa74",
    "045912b3c0e23510d4a3be3d8383b46f38289d13002754e4b1789f3094660a81944d3e7214409a8afa40f4841801a7676216cd0d5f24589baaafb807aecb0d1edb",
    "04bcd736a7ca7624f581c7951b8d4493d417491e7a7b512f0c7eb53fbdaad72da05336d834c58b4f750f196d58c943cae2d8f6449a4eb99525bca4fb34ae5e8334",
    "0416d01825b6be114d5e8af6fe7802b83457e7d94f36ef8ea3ed2804d3af9346b74685c0e17c630d3528c5be3a293ae9bca55bc215b0e332ea968b0ee82a9dc69a",
    "04723e26e9455b76610592d45f38e0d9fb0c3902b4771ad23e32c7a0e6363146f8f0048fb94d4966f384cd8c5c4b2d967cc86aafd70f08c8fb7b39f661ec9d338b",
    "049b3fc65a93b028b8feb2e356892cedca4b958196bbcc140fa024d1fe68503c25b4fc4ccbe7c772c34b9d8740079236f83522d8ecbf794b772e10b7e1a022db45",
    "04e8782b434f8765f137cbef6865cda2fbe8920da489785b5ecec81d4c46fcf28d06b5397f0a08f656c3754d045b1ffcb94c016ca31aca46a90a519fbcefc22bf9",
    "047f7edaab8973562ca5214d5caba140dde7447866a5adbb304a2ccf99e3688f4b0381c7bc6ae4dbb30f9753f3cc6cfe2f6e26b6fc8812d411cb43fdcde39f9d05",
    "04fd184f185681c5b1b1f206896a1a13eacb505636bd5534a01c8c1dbe9ed3f4eef2266e6bb377a88fe2f56c01c88ff19dee041d364fd1bf984f40f15b3061055b",
    "041a7d558b9d5d1e3b50b2f1cff068393d449da83ab47472dfa151eec34e23f26e1d3eaaf12f9bb0fbd59668b90be6bfac197dd652b213e5569a4d316c32b8d16a",
    "04154123f8dbc7841a48c25814e90d7668e9285d843d536d8cebdda0a06c5fa5d25e87f93dd058fa0336965bdeb83cd578a4569b28c1c9a5d3a6dea3c8042e1c6e",
    "04fb1c1ddcd457152ad7e3c22b155782327b2c4686cef8f97f9cafb9f331015f8f2ee986f05c85f7320b162e4b626634850072191de3e268181d78de3efab06b14",
    "04fa1e7d7c7b81e8d42bd91ee5e2cccfbafc5c8367aa47f100ff96bdac6c9a3b1f4e8237d130139777c1ac75bbb0c915d6098bd2e2c089b7025bf14d9e2e4b2e1c",
    "046040f39a4f1f0bbbc3cf8975a23ab25adc65c080819a105520139ed0225058e5e57f0063013c698f1f922448b859d328e18f3c9c8b352310ce300d2734bb3479",
    "0414eb4aea96b26b4b34672c61c482aa5427e83b8bcdceeddc10c1d167e1d8b6fca4be7dd673956be39e4ebfebdf9f908e140e2d303ec1e78518dbfd7ef56993d5",
    "04920ee6b11d109ae6f2abe3bd323a0a6af12fb383f846be44cc23d68897c6c8012568e6e0c2931a754feca0f6cdc32cded141ffc7856f31b4778077fea7978408",
    "04de79880cf0a649e28fedc00616ab55ec4cb00f11ca29118d2ef55ecd97a77d72148660689462f4135d7b1d77de521a177b56eaaf3e5c86a65b8bf2fdb421c4bd",
    "04b4aa6868665d1abe84af0ec75bf6e01740dc5f4df377eb2c4cbe5135275fce529cee4907697db3602628d53805d4b4dbb8f914cd63a4a89703a4eda286beddeb",
    "0472b558bf4f0e29300de6a95e74449b2bd1147621067eeaccc2a141d702586215cd1391db965c75f9416881230f2684edc300eaa88d09d818d04e65777c89f8d5",
    "04c205ea83ccb33a29e3b044a4b4dfe16c0431803637377a511c98a9a28ba336ef09d94476e9ac476a8a3c8d0e163cf47f967fcd3628cc6571f66c90d6dff09fc3",
    "04ff3ea20e1c94fda4638e945b5574b28adf60e59acd39596cc4bb8047970dcf593ef1f430fb126aef32e9ca2154cb62fb37c31718b814262a7fb86cad1a328d17",
    "04045849cfd26fa006a0c4b5965e85982b332689b50bb95fd3ac646c2cf07a45543fab5b89d7a283c1b061c88b2779af24e8e499fa5a4ee3c7a2157d77ceccfc55",
    "04d95831648624c7993689cc59026495864886a7ff401380cc905649fc740815d885617d34b435cbdb29543d3649a020299cacbf5751714a6deda13e2c08956e29",
    "0428a3ee4bf1e9f528b9dc0b49a663462e63666e3b04dd69342beb5c2513a402010a4a4e301ef974b1bec8393904692f808794ff084d3a31b5cd7772d123a11303",
    "04bfa3a7ec249a85251243d33e3532e0473a65344a6e308bc7d3b444987a02a94273f6318ce9c724b2a8ae2df7516d0fd65da712b5ec26617d44788dd4c2a20fbe",
    "0405158992629fea81f694160a3e96a9937c57d3e4eabc2aff303a4bb8ff9d7edcbe9d9b007772b3b617a822d6086125ac0eb1cf6fddddd0e31dd3df07905608e1",
    "041f0a7f066940a4e153302e3f128aedaa1c688b5e4e1c6274130659b60e0577a288293b8af42b04b77780a9d58953f23f3705df57c5cdd66f58f23b8487751f82",
    "04edf5af773377fe0d19ad82be8ec29ab852930c07cc7d906e234b70dbdcadfc6428012a6e50f7506d9ea6544c1738852f5cef63bb808ad69e938176a1e12ed4f0",
    "04a6fbcb69bcf795c98960b26d2cb310a2774bc9c0a50c36adbee35da4ebb18e6bd98b1150397b91626b178640d92ffc22b26575f534ab51bd7c54cf233e84d962",
    "04622df101330dbd04db859a6f20f3d1296fc5eb6089bc8b982d65ae88cabeebaa94d7481ed19a330d6496d1f174ea3c1fe235a516c1e2044cc74f750882bb86f3",
    "04ae9a89a5c195c4f6326580e1ac9b293c162c1a170def140098aabf8c08bbfc5708591fe7fec782a1ed8bfe854bb7a78ae1a68e194ffa7b113b791ba9bb0fba66",
    "0453507cfe08a25297f44b1f4c766d22931937a7e99456acde8f14a0c45fd3f17bf363502106f08937eea5e199ad7befdacbc07a380f82a060e5d5caf250059479",
    "04e2bcf7ea39f2a2026b381f42be78ea780cd6f049020356130847ac4a6d4d010377d1f334adcee47da6466fe1942fc71da3d3e97644da92d52d48230fddd29cc6",
    "046c8d08bba62d613ab19947f5d3ae96eca144794cdfc0885d56b0d07dc8cba3b6b3ecbb9da6829d1c900fdac39c2a22dfe29f551b106abe32302cbde98d90d687",
    "048009bcfd3ef79c0c120c384b65fe31dc80057ce890c1349a7951305cc7de9953dc8dca4783ba6aacbe260513e38e59d6df23fd334487885d0678d73bb672269a",
    "04fc81a16b9eaf7317559e575e34c7e5cd83b9a2244c485915366131aabbc4800fd22d38c3c70e7c1de5e1ccd884af6ea4c3cdafc1bcb8a084f13d713e2d22c1b8",
    "04c58a24011729548f8233ba854ce735b0fff30d07cb7936abdceab9e384f0083dd6f242876a5768141e1603361ecc2cbae0f2ba2707926e1d376e261063d24a4b",
    "048b5d9dca9ca82b8a00f53127e1741ce1d04b59b9631aa5da00579f4f005dfbdaee632bfec35a6d1c152277d914e62060d78fb25f20ec5c8bbf25da556af0ce3d",
    "0463be428126b752641d8bccab70cb6f1731c29dcbc1e8855d2809b558da96f9c012ca36c7cee48925b2642f0e127a7a6878cfd32a2ad459eace7330906a4a6904",
    "048cd313cc58ba47d6699c806cda94984f94292ee804fbd95058cd012ad00a7f5bb89e2fe2530e9ae6fe5aa646defc167ba294eef543dd2bb2f75753a6627e9b00",
    "0433e0f579f4d3f619d22e08679f8c57b6d078ca267bdc2239d5683ca502c55b9bb2f939871e637cdaa47869629c64888e7da9395133932d75f4a7cea4ee2df2df",
    "04e3fe192e48a594dd16c68fc60e92a8dee3e96ba18f6f49ad23be5c47e36002b36299698104c03170583d5bb8ff85cab0dabf4c74c1cc3d6a02188dd51c152b36",
    "046c804fc27d5947dc85d30a2e39634228f8664a7eae758f1f07e44362807c4acdcb922256dd05e9845323075a5672a835a0c487a9d59fca5559a54a0bbeff7bf6",
    "04ccd533168bcd5e53e8e679cafd083df6c4d4906afa193a49fae5063020a54db3f9c4b87811b4e45a53e6c7543b65544353cc118889087cefaa0b2fab9a2711a5",
    "049a9d938bee72be4d6f889f48327246dfe8237545f21e791d4006a354ff0f7b9ce09e1de36276f1cc0af43ec3ab14533cffca1c912f6174bbb74f9bc8998b5900",
    "04c323935239fbb1293914c916b63a424bbcb316e632bf962a347f7bd6c46f7f323870f6727f70ae88ff013eeef4d118fb428e502edce44c2aa583748bc73e9299",
    "04e4421559719b2720478e7cc95a66abb876a48063b2e0021e30dee0ae106638d2f85647039f5488dfac29c0f4c306ab66c342599f0e25224530f22c6ebe9d70dd",
    "04a1572c2f90c863b625d1aa0b97cbc088130a4f2599919fc48d9021f63bb683bbf95b2c3ba36967af0f6f2192aebccd61f6f011eb8602fa90b6fbd9ec450995a4",
    "049f1c217f824cd3c6b7ccf45c1c8e8111d8451cbd9389461c2951be07ec868818fdc5dbd165ce7b6439bf1bced12e04efee1dc1ec90d2d8ad5024796ab55fde88",
    "0452516347db57e42249a435872d001f04dba5006db0c5baa37c7f2ceba92b86f1a37e9907120e1a08fe9415f1df45761ccfcc3ae8d9b5d408c434838db07b9d9d",
    "046a52ee7d7fae379a967a2859335bbed5e8186a350cf9a0513d26893976c1eff58ad264cf3c6a04853aa1d614e2fb71899afaf488530aaf3d8e95f14da884bbc7",
    "0438fb62e69fdbfdc0c08ccdcb28bbdd56934cf9e2b89b3bc239e32ba3d586101b4e03c13c09f2b250949ed9bd48726217d20592ad62dec1419d706732db37a6db",
    "04ac34d6ca1892f2ec835fd12709637c9ef0a5e06366db08c238fb0fc6fae6511daa4ce72dd6c136938d29e13d87c1c6f2b0e5fa93dc3649894e6046a176b10955",
    "0401a3176d3b6ee85c2eef9cb1ffa733afa88db4edafdcb8189c157b43326c6d9d3015cb820b43f4bb731967f5fbe8298057a1cec26dad85f389d2706532900211",
    "046cac85fc0cb04a480bff1b8b8b18b3ce5917adc8a8bccd9567ec4837d6d2f877a99ea49fbc1df67622f5473abe9b110118c6d9207fea8afc4d0b2b9e7a237c98",
    "0409a57fed75dddf06d59641f580bede403b70219dfc14fa7894386b62a9a09f50e1d1f59444dacb1163a92d18cffe73e6af4e9937822e2e26ded6341a808514f9",
    "04b0379fce5330ad3bc50ebaa8cd860654bc3b458645b2a205e40bdb76cb7b6c7b1556ad3f901b76c263047a2570ae386927321c596be0b5d9fcf09bb47e46ede2",
    "04313b5039c3e238c87065211d24a1691472066ca5ecb0010f0530d6f51c5c1c6dddbd3a38f37f7d4267027e83976f462b7fd195120d4eff2c19bfbff00e51305f",
    "04e789005213ab929c2cef86128f750e26bae25cce16afed2c3a503ba9e56a5d024f123fce48d87343554e84a6d4b27db26c0e53775d4c297899caf259971c3c76",
    "043085aad8ec7229614e32a71ebd560c7b60d9f78cec7a08ecec680f714bfd870c42c54f578f5129120ca0e3481020b9c85c0b00631a9e38c1d79ca739f79cd019",
    "0450c7d7a52d840750492065a3a95dab90a2f278c042bc5d0102b1095a268bc51a36570e5cafeee23ff1aefba4c75f39909f1d1165d5642cd579f30c91db59867d",
    "047d69a2736aaa3d3cab83bc7babc6353f418b66ab1ec92ba991edfbdee944bcaa9a03401b221f8186119bb85575510c6cd450a9255bf00f74c837f8d07fac941e",
    "04ab87f149f583418a3a6ea51a520e97d401bd908ebdc6703bd7ae471132a9ca9468cba7779b98c3a9c2bc2ad384d9eeba5b7d077aa9ca0eb531c77d0c380498a9",
    "04a8fc98d0a2f7d808f3c8664e7c2bef106c08d210f868d585bde7647c013cbaa11f8311baf039af2a8963ef0ff1feee9caf4668524a66df8008b0025e01ff950f",
    "0470c9f720389a0572ef2950fd73d23b64a982f5077736c79b5957acdea7f9b7a5ffdec74b3df63f08d3bedc9b33c4e286cc6d9b2e299813db9dbe6c48b8cd0627",
    "044bb192ce9b99c46e5d79ac121b74cce997ad8a74e9c8d8b59a75ba20339780b6aa5e6008a00c5f9ebfb3be3abfc36473975c32f66a8303712321effe2c1c150e",
    "0494d798dfe38374f7359afa28cd38365b8cf7a2b747434bb3869e595cce2ac7b47d2022d385e3395bcc7fae7d6e0eeaf0bbe7175b834fc129a81bf275523ea84e",
    "0421c1aa89246575bf639a725af8065f09973111681d6dc7b3d98791ed869488d0ad68f5fe9b92ba5cadb03f4df3e0687cdbb60192b09dfe7de291acdbcaa398fa",
    "0473ad54f7c5bedc5a2a1e76abcbf407e1cecae70846e5ff8424e9302bb8f73e228cd9f87b5835405785adc17a5ea2364c9b5912d5beaefd7d6d46cfab68015585",
    "04dbb2999d38196169d77e71922c84dd562c4d4e71a9851fa42be00015eb76c9f13b02915e2df4dbe1d8edbe8da7838ea28d193590aa1691c850e54dda09d5b6d8",
    "046045ffc49f521d67657ba9415a34bcb25a718dbe143ef5e8a805fa4b3dfc0415d059104c1a8db2c4d33db627dd79d72af50b04e8b62eeebfa4f99c151f499dd3",
    "048e6b6ce7ef30cc8bf258595178fd4de2721e84274a4d2313d8bf8ee35c5d61b577255eacf586be34d7b15f605658e970ac63e114ce1ac63582be5fd9afae091b",
    "04b8904293e9dd17bbe4d7f803cf5c38b64993c3149cd74e1169bf3b70c39b42723b4aaca089bf5a9717b3f13cc814edec69edf833ef52a7ef221771978af03c23",
    "046da0a3f8c5d6d40de391e994563ad4a73b9cb14a04f4329300689f39695459ba4fe59518f3d3f92ac6c267ba3053ede66373ac209c1fd4090cee8d97f3418e41",
    "049ea3666ec102403ee3c8227a843da479556a8a8696276d772a3dcd9901cfc32b84df972175532f5758ee0e4df18981a9e1012372c634f3787923947050d05fe2",
    "0445e90a91b8fe62c981bce0369eb9dab5f4e4f38eb06305b1b7bb6d86a3dd378b016a26177245eec1b5e0537d50b5bf65a197f6a0f6f09c71ec47036a4fafdf7f",
    "04f821a6ea82c38e1b9efcab958e8b1a660931d3ab48a902d1cfffc09321e17e1ea4eeb19008f671a236fe3a006a80e435c798c9292d29d0f8cab515b1a997c2ac",
    "04c2cbd49bb4660d510332edbf37f293dd6a4a54a90ad7d21377a9ee8b1df6f69479c45badbad5719303c24bdc95ec90bf6c643590b6a13370bcd18a2742687b98",
    "04b7fe8b36d86a5d5e688c138bcb09013307f91745a059af020ba3739d14426d799c271cbb897b97d161556742956346343e81df7589fb12a7cba9a03c7b507b2a",
    "04ce4fcadf4964ec6c189362f403dd8dad625cb062db028c9100488b8a776ec5836b9028bb29d7ce92b3eaca25483351ae282da57bd6d0ec57b0770456fcb2eb0c",
    "0488ed498ac3815ba398aa79deb69ad1375b2e758cc1d92bc586013bf82665a9b4582d687493d1f4a2cd17ef48e49d2857e1a7f6242de8092947c5e34e3f181641",
  ],
};

const colors = {
  teal: {
    bg: "#87D0C6",
    text: "#040707",
    type: "Two Weeks Child",
  },
  purple: {
    bg: "#573494",
    text: "#ffffff",
    type: "Children",
  },
  lightGreen: {
    bg: "#ACD088",
    text: "#040707",
    type: "Week 1 & 2",
    type2: "Standard & Builder",
  },
  blue: {
    bg: "#3BAFE1",
    text: "#040707",
    type: "Week 2 & 3",
    type2: "Standard & Builder",
  },
  pink: {
    bg: "#F48D99",
    text: "#040707",
    type: "Week 3 & 4",
    type2: "Standard & Builder",
  },

  darkGreen: {
    bg: "#0E7B3D",
    text: "#ffffff",
    type: "Team",
  },
  black: {
    bg: "#040707",
    text: "#ffffff",
    type: "Month Standard &",
    type2: "Builder & Scholar",
  },
};

/*
  Elements
*/

const screens = document.querySelectorAll(".body");
const fail = document.querySelector("#fail");
const invalid = document.querySelector("#invalid");
const success = document.querySelector("#success");
const activeCls = "body--active";
const successType = document.querySelector("#success-type");
const initialScanButtons = document.querySelectorAll(".js-scan");

/*
  Helper functions
*/

function getColorForString(str) {
  const lowerCaseStr = str.toLowerCase(); // Convert the key to lowercase

  for (const [color, hashes] of Object.entries(keys)) {
    if (hashes.includes(lowerCaseStr)) {
      return {
        color,
        info: colors[color],
      };
    }
  }

  return null;
}

function hideAllScreens() {
  screens.forEach((el) => el.classList.remove(activeCls));
}

function getText(info) {
  let text = info.info.type;

  if (info.info.type2) {
    text += `<br>${info.info.type2}`;
  }
  return text;
}

function handleKey(key) {
  // Find info on the key
  const info = getColorForString(key);

  console.log(info);

  if (!info) {
    // Hide active screens
    hideAllScreens();

    // Display success
    invalid.classList.add(activeCls);
  } else {
    // Set class on body
    document.body.className = info.color;

    // Update type
    successType.innerHTML = getText(info);

    // Hide active screens
    hideAllScreens();

    // Display success
    success.classList.add(activeCls);
  }
}

/*
  Event listeners
*/

const url = new URL(window.location, true);
if (url.query.pk1) handleKey(url.query.pk1);

initialScanButtons.forEach((btn) => {
  btn.addEventListener("click", async (e) => {
    e.preventDefault();

    try {
      const res = await execHaloCmdWeb({
        name: "get_pkeys",
      });

      handleKey(res.publicKeys["1"]);
    } catch (err) {
      hideAllScreens();
      fail.classList.add(activeCls);
    }
  });
});
